import React, { useState, useEffect } from 'react';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';

import './App.css';

import { Row } from 'react-bootstrap';

/*
NOTES TO SELF:
-looks like to host, server needs to be running node. Not sure why
-I've put up file from build on processprocession.com>public and it's serving fine
*/

const allQuestions = [
  {"q": "What emotion do you feel when you look at this image? ",
    "a1" : "Sadness",
    "a2" : "Empathy",
    "a3" : "Anger",
    "a4" : "Disinterest",
    "other" : "default",
    "imageURL" : "/images/Oooo.jpeg"
  },
  {"q": "What do you see in this image?",
    "a1" : "A family having dinner",
    "a2" : "Friends having a meeting",
    "a3" : "Strangers at a conference",
    "a4" : "Nothing in particular",
    "other" : "default",
    "imageURL" : "/images/Ark.jpeg"
  },
  {"q": "What is the first thing that comes to your mind when you look at this image?",
    "a1" : "Peaceful",
    "a2" : "Romantic",
    "a3" : "Melancholic",
    "a4" : "Nothing in particular",
    "other" : "default",
    "imageURL" : "/images/Birds.jpeg"
  },
  {"q": "What emotions do you feel when you look at this image?",
    "a1" : "Sadness",
    "a2" : "Empathy",
    "a3" : "Anger",
    "a4" : "Disinterest",
    "other" : "default",
    "imageURL" : "/images/Fence.jpeg"
  },
  {"q": "What is the main message or theme you get from this image?",
    "a1" : "Loneliness",
    "a2" : "Individualism",
    "a3" : "Isoloation",
    "a4" : "Nothing in particular",
    "other" : "default",
    "imageURL" : "/images/Fix_It_Please.jpeg"
  },
  {"q": "What do you think is the story behind this image? ",
    "a1" : "Love story",
    "a2" : "Vacation",
    "a3" : "Shore leave",
    "a4" : "Nothing in particular",
    "other" : "default",
    "imageURL" : "/images/Flag_Whale.jpeg"
  },
  {"q": "How does this image make you feel? ",
    "a1" : "Excitement",
    "a2" : "Calm",
    "a3" : "Overwhelmed",
    "a4" : "Nothing in particular",
    "other" : "default",
    "imageURL" : "/images/Go.jpeg"
  },
  {"q": "What do you think is happening in this image?",
    "a1" : "Self-reflection",
    "a2" : "Getting ready",
    "a3" : "Prayer",
    "a4" : "Nothing in particular",
    "other" : "default",
    "imageURL" : "/images/Goo.jpeg"
  }
  
]


function CheckExample(props) {
  //useEffect will trigger anytime the item in brackets updates
  useEffect(() => {
      console.log("question index updated!! "+props.questionIndex);
      //update checks to clear all
      document.getElementById("quesRadio1").checked = false;
      document.getElementById("quesRadio2").checked = false;
      document.getElementById("quesRadio3").checked = false;
      document.getElementById("quesRadio4").checked = false;
      document.getElementById("exampleForm.ControlTextarea1").value = "";
    }, [props.questionIndex]
  )
  return (
    <Form>
        <div key={`default-'radio'`} className="mb-3">
          <Form.Check 
            type='radio'
            name='answerradio'
            label={allQuestions[props.questionIndex].a1}
            id={`quesRadio1`}
          />
          <Form.Check
            type='radio'
            name='answerradio'
            label={allQuestions[props.questionIndex].a2}
            id={`quesRadio2`}
          />
          <Form.Check
            type='radio'
            name='answerradio'
            label={allQuestions[props.questionIndex].a3}
            id={`quesRadio3`}
          />
          <Form.Check
            type='radio'
            name='answerradio'
            label={allQuestions[props.questionIndex].a4}
            id={`quesRadio4`}
          />
        </div>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>Other</Form.Label>
          <Form.Control as="textarea" rows={3} />
        </Form.Group>
    </Form>
  );
}

const App = () => {
  const [questionIndex, updateQuestionIndex] = useState(0);

  //instead of other functions, this?
  const incrementIndex = () => {
    var maxIndex = allQuestions.length;
    var nextIndex = questionIndex+1;
    if (nextIndex >= maxIndex){
      nextIndex = 0;
    }
    updateQuestionIndex(nextIndex);
  }

  return (
  <Container xs lg="2" className="p-3">
    <div>
      <h1 className="header">Thematic Apperception Test</h1>
      <Image src={allQuestions[questionIndex].imageURL} id="questionImage"/>
      <Row>
        <Col>{allQuestions[questionIndex].q}</Col>
        <CheckExample questionIndex={questionIndex} />
      </Row>
    </div>
    <Button>Previous</Button>
    <Button onClick={() => {incrementIndex();}}>Next</Button>
  </Container>
  );
};

export default App;
